type makeSelectValueProps = {
  label: string,
  value: string,
  array: Array<any> 
}

type makeSelectValueWithTypeProps = {
  label: string,
  type: string,
  value: string,
  array: Array<any> 
}

export function makeSelectValues ({ label, value, array }: makeSelectValueProps) {
  return array?.map(arrayItem => ({
    label: arrayItem[label],
    value: arrayItem[value],
  }))
}

export function makeSelectValuesWithType ({ label, type, value, array }: makeSelectValueWithTypeProps) {
  return array?.map(arrayItem => ({
    label: `${arrayItem[label]} (${getNestedValue(arrayItem, type)})`,
    value: arrayItem[value],
  }))
}

function getNestedValue(obj: any, path: string) {
  return path.split('.').reduce((acc, key) => acc?.[key], obj);
}
