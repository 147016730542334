import React, { useState } from "react";
import { Put, LoadStep } from "@/domain/usecase";
import {
  Button,
  Modal,
  Messages,
  IconButton,
  ProgressIndicator,
  Switch
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { PREFIX_REGISTER_DISABLESTEP_BUTTON_CLOSE, PREFIX_REGISTER_DISABLESTEP_BUTTON_SUBMIT, PREFIX_REGISTER_DISABLESTEP_ICONBUTTON_TOOLTIPACTIVEDISABLED, PREFIX_REGISTER_DISABLESTEP_MODAL, PREFIX_REGISTER_DISABLESTEP_SWITCH } from "@/ids";

type DisableStepProps = {
  putStep: Put<any>;
  loadStep: LoadStep;
  id: number;
  checked: boolean
};

export function DisableStep({ putStep, loadStep, id, checked }: DisableStepProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const { data } = useQuery<any>({
    queryKey: [`step-${id}`],
    queryFn: async () => await loadStep.load(id),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    putStep
      .put(id, {
        ...data,
        isActive: !checked,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["step-list"] });
        queryClient.invalidateQueries({ queryKey: [`step-${id}`] });
        handleClose();
        setResponseMessage({
          message: checked
            ? t("components.disable-step.response-success-message.active")
            : t("components.disable-step.response-success-message.disabled"),
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        id={PREFIX_REGISTER_DISABLESTEP_ICONBUTTON_TOOLTIPACTIVEDISABLED}
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={checked
          ? t("components.disable-step.open-button-tooltip.active")
          : t("components.disable-step.open-button-tooltip.disabled")
        }
      >
        <Switch
          id={PREFIX_REGISTER_DISABLESTEP_SWITCH}
          checked={checked}
        />
      </IconButton>
      <Modal
        id={PREFIX_REGISTER_DISABLESTEP_MODAL}
        width={600}
        title={checked
          ? t("components.disable-step.title.active")
          : t("components.disable-step.title.disabled")
        }
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_DISABLESTEP_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.disable-step.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_DISABLESTEP_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.disable-step.submit-button")}
          />,
        ]}
      >
        <span>
          {checked
            ? t("components.disable-step.content.active")
            : t("components.disable-step.content.disabled")
          } {data?.name}?
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
