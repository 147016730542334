// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@sirius\\/yard-control-fe {
  position: absolute;
  top: 64px;
  left: 88px;
  width: calc(100% - 88px);
  height: calc(100% - 64px);
  font-family: 'DMSans-Regular';
  overflow: auto;
  overscroll-behavior: contain;
  z-index: 1;
}

#single-spa-application\\:\\@sirius\\/yard-control-fe:empty {
  display: none;
}

strong {
  font-size: 14px;
}

`, "",{"version":3,"sources":["webpack://./src/main/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,wBAAwB;EACxB,yBAAyB;EACzB,6BAA6B;EAC7B,cAAc;EACd,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":["#single-spa-application\\:\\@sirius\\/yard-control-fe {\n  position: absolute;\n  top: 64px;\n  left: 88px;\n  width: calc(100% - 88px);\n  height: calc(100% - 64px);\n  font-family: 'DMSans-Regular';\n  overflow: auto;\n  overscroll-behavior: contain;\n  z-index: 1;\n}\n\n#single-spa-application\\:\\@sirius\\/yard-control-fe:empty {\n  display: none;\n}\n\nstrong {\n  font-size: 14px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
