import React, { useState } from "react";
import { UpdateSpot as DomainUpdateSpot, LoadSpot } from "@/domain/usecase";
import {
  Button,
  Modal,
  Messages,
  IconButton,
  ProgressIndicator,
  Switch
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { mockLoadSpotModel } from "@/domain/mocks";
import {
  PREFIX_REGISTER_DISABLESPOT_ICONBUTTON_TOOLTIPACTIVEDISABLED,
  PREFIX_REGISTER_DISABLESPOT_SWITCH,
  PREFIX_REGISTER_DISABLESPOT_MODAL,
  PREFIX_REGISTER_DISABLESPOT_BUTTON_CLOSE,
  PREFIX_REGISTER_DISABLESPOT_BUTTON_SUBMIT,
} from "@/ids";

type DisableSpotProps = {
  updateSpot: DomainUpdateSpot;
  loadSpot: LoadSpot;
  id: number;
  checked: boolean
};

export function DisableSpot({ updateSpot, loadSpot, id, checked }: DisableSpotProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const { data } = useQuery<any>({
    queryKey: [`spot-${id}`],
    queryFn: async () => await loadSpot.load(id),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    updateSpot
      .update(id, {
        ...data,
        isActive: !checked,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["spot-list"] });
        handleClose();
        setResponseMessage({
          message: checked
            ? t("components.disable-spot.response-success-message.active")
            : t("components.disable-spot.response-success-message.disabled"),
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        id={PREFIX_REGISTER_DISABLESPOT_ICONBUTTON_TOOLTIPACTIVEDISABLED}
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={checked
          ? t("components.disable-spot.open-button-tooltip.active")
          : t("components.disable-spot.open-button-tooltip.disabled")
        }
      >
        <Switch id={PREFIX_REGISTER_DISABLESPOT_SWITCH} checked={checked} />
      </IconButton>
      <Modal
        id={PREFIX_REGISTER_DISABLESPOT_MODAL}
        width={600}
        title={checked
          ? t("components.disable-spot.title.active")
          : t("components.disable-spot.title.disabled")
        }
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_DISABLESPOT_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.disable-spot.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_DISABLESPOT_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.disable-spot.submit-button")}
          />,
        ]}
      >
        <span>
          {checked
            ? t("components.disable-spot.content.active")
            : t("components.disable-spot.content.disabled")
          } {data?.name}?
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
