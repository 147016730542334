import { PREFIX_IDS } from '@/main/constants'

// [REGISTER]

// ADD-DOCK
export const PREFIX_REGISTER_ADDDOCK_BUTTON_OPEN = `${PREFIX_IDS}prefix_register_add_dock_button_open`
export const PREFIX_REGISTER_ADDDOCK_MODAL = `${PREFIX_IDS}prefix_register_add_dock_modal`
export const PREFIX_REGISTER_ADDDOCK_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add_dock_button_close`
export const PREFIX_REGISTER_ADDDOCK_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_add_dock_button_submit`
export const PREFIX_REGISTER_ADDDOCK_FORMCONTROL_INPUTNAME = `${PREFIX_IDS}prefix_register_add_dock_form_control_input_name`
export const PREFIX_REGISTER_ADDDOCK_INPUT_NAME = `${PREFIX_IDS}prefix_register_add_dock_input_name`
export const PREFIX_REGISTER_ADDDOCK_FORMCONTROL_INPUTDESCRIPTION = `${PREFIX_IDS}prefix_register_add_dock_form_control_input_description`
export const PREFIX_REGISTER_ADDDOCK_INPUT_DESCRIPTION = `${PREFIX_IDS}prefix_register_add_dock_input_description`
export const PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTMATERIALFAMILY = `${PREFIX_IDS}prefix_register_add_dock_form_control_select_material_family`
export const PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTVEHICLECOMPOSITION = `${PREFIX_IDS}prefix_register_add_dock_form_control_select_vehicle_composition`
export const PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTMOVEMENTTYPE = `${PREFIX_IDS}prefix_register_add_dock_form_control_select_movement_type`
export const PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTDOCKSTATUS = `${PREFIX_IDS}prefix_register_add_dock_form_control_select_dock_status`

// ADD-MATERIAL-FAMILY
export const PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_OPEN = `${PREFIX_IDS}prefix_register_add_material_family_button_open`
export const PREFIX_REGISTER_ADDMATERIALFAMILY_MODAL = `${PREFIX_IDS}prefix_register_add_material_family_modal`
export const PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add_material_family_button_close`
export const PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_add_material_family_button_submit`
export const PREFIX_REGISTER_ADDMATERIALFAMILY_FORMCONTROL_INPUTNAME = `${PREFIX_IDS}prefix_register_add_material_family_form_control_input_name`
export const PREFIX_REGISTER_ADDMATERIALFAMILY_INPUT_NAME = `${PREFIX_IDS}prefix_register_add_material_family_input_name`
export const PREFIX_REGISTER_ADDMATERIALFAMILY_FORMCONTROL_SELECTMATERIALCLASSIFICATION = `${PREFIX_IDS}prefix_register_add_material_family_form_control_select_material_classification`

// ADD-SPOT
export const PREFIX_REGISTER_ADDSPOT_BUTTON_OPEN = `${PREFIX_IDS}prefix_register_add_spot_button_open`
export const PREFIX_REGISTER_ADDSPOT_MODAL = `${PREFIX_IDS}prefix_register_add_spot_modal`
export const PREFIX_REGISTER_ADDSPOT_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add_spot_button_close`
export const PREFIX_REGISTER_ADDSPOT_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_add_spot_button_submit`
export const PREFIX_REGISTER_ADDSPOT_FORMCONTROL_INPUTNAME = `${PREFIX_IDS}prefix_register_add_spot_form_control_input_name`
export const PREFIX_REGISTER_ADDSPOT_INPUT_NAME = `${PREFIX_IDS}prefix_register_add_spot_input_name`
export const PREFIX_REGISTER_ADDSPOT_FORMCONTROL_INPUTLOCATION = `${PREFIX_IDS}prefix_register_add_spot_form_control_input_location`
export const PREFIX_REGISTER_ADDSPOT_INPUT_LOCATION = `${PREFIX_IDS}prefix_register_add_spot_input_location`
export const PREFIX_REGISTER_ADDSPOT_FORMCONTROL_SELECTVEHICLECOMPOSITION = `${PREFIX_IDS}prefix_register_add_spot_form_control_select_vehicle_composition`
export const PREFIX_REGISTER_ADDSPOT_FORMCONTROL_SELECTSPOTSTATUS = `${PREFIX_IDS}prefix_register_add_spot_form_control_select_spot_status`

// ADD-VEHICLE-COMPOSITION
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_LABELCELL_VEHICLETYPE = `${PREFIX_IDS}prefix_register_add_vehicle_composition_labelcell_vehicle_type`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_LABELCELL_BODYWORK = `${PREFIX_IDS}prefix_register_add_vehicle_composition_labelcell_bodywork`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_ICONBUTTON_EDITTOOLTIP = `${PREFIX_IDS}prefix_register_add_vehicle_composition_icon_button_edit_tooltip`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_ICONBUTTON_REMOVETOOLTIP = `${PREFIX_IDS}prefix_register_add_vehicle_composition_icon_button_remove_tooltip`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_OPEN = `${PREFIX_IDS}prefix_register_add_vehicle_composition_button_open`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_MODAL = `${PREFIX_IDS}prefix_register_add_vehicle_composition_modal`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add_vehicle_composition_button_close`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_add_vehicle_composition_button_submit`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_INPUTNAME = `${PREFIX_IDS}prefix_register_add_vehicle_composition_form_control_input_name`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_INPUT_NAME = `${PREFIX_IDS}prefix_register_add_vehicle_composition_input_name`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_SELECTVEHICLETYPE = `${PREFIX_IDS}prefix_register_add_vehicle_composition_form_control_select_vehicle_type`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_SELECTBODYWORK = `${PREFIX_IDS}prefix_register_add_vehicle_composition_form_control_select_bodywork`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMBUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add_vehicle_composition_formbutton_close`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMBUTTON_SUBMITUPDATE = `${PREFIX_IDS}prefix_register_add_vehicle_composition_formbutton_submit_update`
export const PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_ADDCOMPOSITION = `${PREFIX_IDS}prefix_register_add_vehicle_composition_button_add_composition`

// DISABLE-DOCK
export const PREFIX_REGISTER_DISABLEDOCK_ICONBUTTON_TOOLTIPACTIVEDISABLED = `${PREFIX_IDS}prefix_register_disable_dock_icon_button_tooltip_active_disabled`
export const PREFIX_REGISTER_DISABLEDOCK_SWITCH = `${PREFIX_IDS}prefix_register_disable_dock_switch`
export const PREFIX_REGISTER_DISABLEDOCK_MODAL = `${PREFIX_IDS}prefix_register_disable_dock_modal`
export const PREFIX_REGISTER_DISABLEDOCK_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_disable_dock_button_close`
export const PREFIX_REGISTER_DISABLEDOCK_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_disable_dock_button_submit`

// DISABLE-MATERIAL-FAMILY
export const PREFIX_REGISTER_DISABLEMATERIALFAMILY_ICONBUTTON_TOOLTIPACTIVEDISABLED = `${PREFIX_IDS}prefix_register_disable_material_family_icon_button_tooltip_active_disabled`
export const PREFIX_REGISTER_DISABLEMATERIALFAMILY_SWITCH = `${PREFIX_IDS}prefix_register_disable_material_family_switch`
export const PREFIX_REGISTER_DISABLEMATERIALFAMILY_MODAL = `${PREFIX_IDS}prefix_register_disable_material_family_modal`
export const PREFIX_REGISTER_DISABLEMATERIALFAMILY_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_disable_material_family_button_close`
export const PREFIX_REGISTER_DISABLEMATERIALFAMILY_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_disable_material_family_button_submit`

// DISABLE-SPOT
export const PREFIX_REGISTER_DISABLESPOT_ICONBUTTON_TOOLTIPACTIVEDISABLED = `${PREFIX_IDS}prefix_register_disable_spot_icon_button_tooltip_active_disabled`
export const PREFIX_REGISTER_DISABLESPOT_SWITCH = `${PREFIX_IDS}prefix_register_disable_spot_switch`
export const PREFIX_REGISTER_DISABLESPOT_MODAL = `${PREFIX_IDS}prefix_register_disable_spot_modal`
export const PREFIX_REGISTER_DISABLESPOT_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_disable_spot_button_close`
export const PREFIX_REGISTER_DISABLESPOT_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_disable_spot_button_submit`

// DISABLE-VEHICLE-COMPOSITION
export const PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_ICONBUTTON_TOOLTIPACTIVEDISABLED = `${PREFIX_IDS}prefix_register_disable_vehicle_composition_icon_button_tooltip_active_disabled`
export const PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_SWITCH = `${PREFIX_IDS}prefix_register_disable_vehicle_composition_switch`
export const PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_MODAL = `${PREFIX_IDS}prefix_register_disable_vehicle_composition_modal`
export const PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_disable_vehicle_composition_button_close`
export const PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_disable_vehicle_composition_button_submit`

// UPDATE-DOCK
export const PREFIX_REGISTER_UPDATEDOCK_ICONBUTTON_OPENTOOLTIP = `${PREFIX_IDS}prefix_register_update_dock_icon_button_open_tooltip`
export const PREFIX_REGISTER_UPDATEDOCK_MODAL = `${PREFIX_IDS}prefix_register_update_dock_modal`
export const PREFIX_REGISTER_UPDATEDOCK_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_update_dock_button_close`
export const PREFIX_REGISTER_UPDATEDOCK_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_update_dock_button_submit`
export const PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_INPUTNAME = `${PREFIX_IDS}prefix_register_update_dock_form_control_input_name`
export const PREFIX_REGISTER_UPDATEDOCK_INPUT_NAME = `${PREFIX_IDS}prefix_register_update_dock_input_name`
export const PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_INPUTDESCRIPTION = `${PREFIX_IDS}prefix_register_update_dock_form_control_input_description`
export const PREFIX_REGISTER_UPDATEDOCK_INPUT_DESCRIPTION = `${PREFIX_IDS}prefix_register_update_dock_input_description`
export const PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTMATERIALFAMILY = `${PREFIX_IDS}prefix_register_update_dock_form_control_select_material_family`
export const PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTVEHICLECOMPOSITION = `${PREFIX_IDS}prefix_register_update_dock_form_control_select_vehicle_composition`
export const PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTMOVEMENTTYPE = `${PREFIX_IDS}prefix_register_update_dock_form_control_select_movement_type`
export const PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTDOCKSTATUS = `${PREFIX_IDS}prefix_register_update_dock_form_control_select_dock_status`

// UPDATE-MATERIAL-FAMILY
export const PREFIX_REGISTER_UPDATEMATERIALFAMILY_ICONBUTTON_OPENTOOLTIP = `${PREFIX_IDS}prefix_register_update_material_family_icon_button_open_tooltip`
export const PREFIX_REGISTER_UPDATEMATERIALFAMILY_MODAL = `${PREFIX_IDS}prefix_register_update_material_family_modal`
export const PREFIX_REGISTER_UPDATEMATERIALFAMILY_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_update_material_family_button_close`
export const PREFIX_REGISTER_UPDATEMATERIALFAMILY_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_update_material_family_button_submit`
export const PREFIX_REGISTER_UPDATEMATERIALFAMILY_FORMCONTROL_INPUTNAME = `${PREFIX_IDS}prefix_register_update_material_family_form_control_input_name`
export const PREFIX_REGISTER_UPDATEMATERIALFAMILY_INPUT_NAME = `${PREFIX_IDS}prefix_register_update_material_family_input_name`
export const PREFIX_REGISTER_UPDATEMATERIALFAMILY_FORMCONTROL_SELECTMATERIALCLASSIFICATION = `${PREFIX_IDS}prefix_register_update_material_family_form_control_select_material_classification`

// UPDATE-SPOT
export const PREFIX_REGISTER_UPDATESPOT_ICONBUTTON_OPENTOOLTIP = `${PREFIX_IDS}prefix_register_update_spot_icon_button_open_tooltip`
export const PREFIX_REGISTER_UPDATESPOT_MODAL = `${PREFIX_IDS}prefix_register_update_spot_modal`
export const PREFIX_REGISTER_UPDATESPOT_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_update_spot_button_close`
export const PREFIX_REGISTER_UPDATESPOT_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_update_spot_button_submit`
export const PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_INPUTNAME = `${PREFIX_IDS}prefix_register_update_spot_form_control_input_name`
export const PREFIX_REGISTER_UPDATESPOT_INPUT_NAME = `${PREFIX_IDS}prefix_register_update_spot_input_name`
export const PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_INPUTLOCATION = `${PREFIX_IDS}prefix_register_update_spot_form_control_input_location`
export const PREFIX_REGISTER_UPDATESPOT_INPUT_LOCATION = `${PREFIX_IDS}prefix_register_update_spot_input_location`
export const PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_SELECTVEHICLECOMPOSITION = `${PREFIX_IDS}prefix_register_update_spot_form_control_select_vehicle_composition`
export const PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_SELECTSPOTSTATUS = `${PREFIX_IDS}prefix_register_update_spot_form_control_select_spot_status`

// UPDATE-VEHICLE-COMPOSITION
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_LABELCELL_VEHICLETYPE = `${PREFIX_IDS}prefix_register_update_vehicle_composition_labelcell_vehicle_type`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_LABELCELL_BODYWORK = `${PREFIX_IDS}prefix_register_update_vehicle_composition_labelcell_bodywork`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_ICONBUTTON_OPENTOOLTIP = `${PREFIX_IDS}prefix_register_update_vehicle_composition_icon_button_open_tooltip`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_ICONBUTTON_EDITTOOLTIP = `${PREFIX_IDS}prefix_register_update_vehicle_composition_icon_button_edit_tooltip`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_ICONBUTTON_REMOVETOOLTIP = `${PREFIX_IDS}prefix_register_update_vehicle_composition_icon_button_remove_tooltip`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_BUTTON_OPEN = `${PREFIX_IDS}prefix_register_update_vehicle_composition_button_open`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_MODAL = `${PREFIX_IDS}prefix_register_update_vehicle_composition_modal`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_update_vehicle_composition_button_close`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_update_vehicle_composition_button_submit`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_FORMCONTROL_INPUTNAME = `${PREFIX_IDS}prefix_register_update_vehicle_composition_form_control_input_name`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_INPUT_NAME = `${PREFIX_IDS}prefix_register_update_vehicle_composition_input_name`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_FORMCONTROL_SELECTVEHICLETYPE = `${PREFIX_IDS}prefix_register_update_vehicle_composition_form_control_select_vehicle_type`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_FORMCONTROL_SELECTBODYWORK = `${PREFIX_IDS}prefix_register_update_vehicle_composition_form_control_select_bodywork`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_FORMBUTTON_CLOSE = `${PREFIX_IDS}prefix_register_update_vehicle_composition_formbutton_close`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_FORMBUTTON_SUBMITUPDATE = `${PREFIX_IDS}prefix_register_update_vehicle_composition_formbutton_submit_update`
export const PREFIX_REGISTER_UPDATEVEHICLECOMPOSITION_BUTTON_ADDCOMPOSITION = `${PREFIX_IDS}prefix_register_update_vehicle_composition_button_add_composition`

// LIST-DOCK
export const PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME = `${PREFIX_IDS}prefix_register_list_dock_labelcell_name`
export const PREFIX_REGISTER_LISTDOCK_LABELCELL_STATUS = `${PREFIX_IDS}prefix_register_list_dock_labelcell_status`

// LIST-MATERIAL-FAMILY
export const PREFIX_REGISTER_LISTMATERIALFAMILY_LABELCELL_NAME = `${PREFIX_IDS}prefix_register_list_material_family_labelcell_name`
export const PREFIX_REGISTER_LISTMATERIALFAMILY_LABELCELL_MATERIALCLASSIFICATION = `${PREFIX_IDS}prefix_register_list_material_family_labelcell_material_classification`

// LIST-SPOT
export const PREFIX_REGISTER_LISTSPOT_LABELCELL_NAME = `${PREFIX_IDS}prefix_register_list_spot_labelcell_name`
export const PREFIX_REGISTER_LISTSPOT_LABELCELL_SPOTSTATUS = `${PREFIX_IDS}prefix_register_list_spot_labelcell_spot_status`

// LIST-VEHICLE-COMPOSITION
export const PREFIX_REGISTER_LISTVEHICLECOMPOSITION_LABELCELL_NAME = `${PREFIX_IDS}prefix_register_list_vehicle_composition_labelcell_name`

// ADD-STEP
export const PREFIX_REGISTER_ADDSTEP_BUTTON_OPEN = `${PREFIX_IDS}prefix_register_add_step_button_open`
export const PREFIX_REGISTER_ADDSTEP_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add_step_button_close`
export const PREFIX_REGISTER_ADDSTEP_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_add_step_button_submit`

// UPDATE-STEP
export const PREFIX_REGISTER_UPDATESTEP_BUTTON_OPEN = `${PREFIX_IDS}prefix_register_update_step_button_open`
export const PREFIX_REGISTER_UPDATESTEP_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_update_step_button_close`
export const PREFIX_REGISTER_UPDATESTEP_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_update_step_button_submit`

// DISABLE-STEP
export const PREFIX_REGISTER_DISABLESTEP_ICONBUTTON_TOOLTIPACTIVEDISABLED = `${PREFIX_IDS}prefix_register_disable_step_icon_button_tooltip_active_disabled`
export const PREFIX_REGISTER_DISABLESTEP_SWITCH = `${PREFIX_IDS}prefix_register_disable_step_switch`
export const PREFIX_REGISTER_DISABLESTEP_MODAL = `${PREFIX_IDS}prefix_register_disable_step_modal`
export const PREFIX_REGISTER_DISABLESTEP_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_disable_step_button_close`
export const PREFIX_REGISTER_DISABLESTEP_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_disable_step_button_submit`

// CLONE-STEP
export const PREFIX_REGISTER_CLONESTEP_BUTTON_OPEN = `${PREFIX_IDS}prefix_register_clone_step_button_open`
export const PREFIX_REGISTER_CLONESTEP_MODAL = `${PREFIX_IDS}prefix_register_clone_step_modal`
export const PREFIX_REGISTER_CLONESTEP_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_clone_step_button_close`
export const PREFIX_REGISTER_CLONESTEP_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_clone_step_button_submit`

// STEP-GENERAL-FORM
export const PREFIX_REGISTER_GENERALFORM_FORMCONTROL_INPUT_NAME =  `${PREFIX_IDS}prefix_register_general-form_label_name`
export const PREFIX_REGISTER_GENERALFORM_INPUT_NAME = `${PREFIX_IDS}prefix_register_general-form_input_name`
export const PREFIX_REGISTER_GENERALFORM_FORMCONTROL_INPUT_DESCRIPTION = `${PREFIX_IDS}prefix_register_general-form_label_description`
export const PREFIX_REGISTER_GENERALFORM_INPUT_DESCRIPTION = `${PREFIX_IDS}prefix_register_general-form_input_description`
export const PREFIX_REGISTER_GENERALFORM_FORMCONTROL_SELECT_TYPE = `${PREFIX_IDS}prefix_register_general-form_label_select-type`
export const PREFIX_REGISTER_GENERALFORM_INPUT_PERMANENCE = `${PREFIX_IDS}prefix_register_general-form_input_permanence`

// STEP-DISPLAY-RULES-FORM
export const PREFIX_REGISTER_DISPLAYRULES_BUTTON_ADD =  `${PREFIX_IDS}prefix_register_display-rules_button_add`
export const PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_SELECT_FEAT =  `${PREFIX_IDS}prefix_register_display-rules-form_label_feat`
export const PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_SELECT_OPERATOR =  `${PREFIX_IDS}prefix_register_display-rules-form_label_operator`
export const PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_BUTTON_CANCEL =  `${PREFIX_IDS}prefix_register_display-rules-form_button_cancel`
export const PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_BUTTON_SUBMIT =  `${PREFIX_IDS}prefix_register_display-rules-form_button_submit`

// ADDITIONAL-FIELDS-FORM
export const PREFIX_REGISTER_ADDITIONALFIELDS_BUTTON_ADD =  `${PREFIX_IDS}prefix_register_additional-fields_button_add`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_INPUT_NAME =  `${PREFIX_IDS}prefix_register_additional-fields-form_label_name`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_INPUT_NAME =  `${PREFIX_IDS}prefix_register_additional-fields-form_input_name`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_CHECKLIST =  `${PREFIX_IDS}prefix_register_additional-fields-form_label_checklist`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_SECTION =  `${PREFIX_IDS}prefix_register_additional-fields-form_label_section`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_FIELDTYPE =  `${PREFIX_IDS}prefix_register_additional-fields-form_label_field-type`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SWITCH_REQUIRED =  `${PREFIX_IDS}prefix_register_additional-fields-form_label_required`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_SWITCH_REQUIRED =  `${PREFIX_IDS}prefix_register_additional-fields-form_switch_required`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_BUTTON_CANCEL =  `${PREFIX_IDS}prefix_register_additional-fields-form_button_cancel`
export const PREFIX_REGISTER_ADDITIONALFIELDSFORM_BUTTON_SUBMIT =  `${PREFIX_IDS}prefix_register_additional-fields-form_button_submit`

// INCLUDE STEP
export const PREFIX_REGISTER_INCLUDESTEP_MODAL_TITLE = `${PREFIX_IDS}prefix_register_include-step_modal_title`
export const PREFIX_REGISTER_INCLUDESTEP_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_include-step_button_close`
export const PREFIX_REGISTER_INCLUDESTEP_BUTTON_ADD = `${PREFIX_IDS}prefix_register_include-step_button_add`
export const PREFIX_REGISTER_INCLUDESTEP_SELECT_STEP = `${PREFIX_IDS}prefix_register_include-step_select_step`

// ADD FLOW
export const PREFIX_REGISTER_ADDFLOW_PAPER = `${PREFIX_IDS}prefix_register_add-flow_paper`
export const PREFIX_REGISTER_ADDFLOW_BREADCRUMBS = `${PREFIX_IDS}prefix_register_add-flow_breadcrumbs`
export const PREFIX_REGISTER_ADDFLOW_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_add-flow_button_submit`
export const PREFIX_REGISTER_ADDFLOW_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add-flow_button_close`
export const PREFIX_REGISTER_ADDFLOW_INPUT_NAME_LABEL = `${PREFIX_IDS}prefix_register_add-flow_label_name`
export const PREFIX_REGISTER_ADDFLOW_INPUT_NAME = `${PREFIX_IDS}prefix_register_add-flow_input_name`
export const PREFIX_REGISTER_ADDFLOW_SELECT_MOVEMENTTYPE_LABEL = `${PREFIX_IDS}prefix_register_add-flow_label_movement-type`
export const PREFIX_REGISTER_ADDFLOW_SELECT_MOVEMENTTYPE = `${PREFIX_IDS}prefix_register_add-flow_select_movement-type`
export const PREFIX_REGISTER_ADDFLOW_SELECT_STARTCOUNTING_LABEL = `${PREFIX_IDS}prefix_register_add-flow_label_start-counting`
export const PREFIX_REGISTER_ADDFLOW_SELECT_STARTCOUNTING = `${PREFIX_IDS}prefix_register_add-flow_select_start-counting`
export const PREFIX_REGISTER_ADDFLOW_SELECT_ENDCOUNTING_LABEL = `${PREFIX_IDS}prefix_register_add-flow_label_end-counting`
export const PREFIX_REGISTER_ADDFLOW_SELECT_ENDCOUNTING = `${PREFIX_IDS}prefix_register_add-flow_select_end-counting`
export const PREFIX_REGISTER_ADDFLOW_CARDTREE = `${PREFIX_IDS}prefix_register_add-flow_cardtree`
export const PREFIX_REGISTER_ADDFLOW_LABEL_MESSAGE = `${PREFIX_IDS}prefix_register_add-flow_label_message`
export const PREFIX_REGISTER_ADDFLOW_FORM = `${PREFIX_IDS}prefix_register_add-flow_form`

// ADD FLOW EDIT STEP
export const PREFIX_REGISTER_ADDFLOWEDITSTEP_TITLE = `${PREFIX_IDS}prefix_register_add-flow-edit-step_modal_title`
export const PREFIX_REGISTER_ADDFLOWEDITSTEP_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add-flow-edit-step_button_close`
export const PREFIX_REGISTER_ADDFLOWEDITSTEP_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_add-flow-edit-step_button_submit`
export const PREFIX_REGISTER_ADDFLOWEDITSTEP_LABEL_STEPNAME = `${PREFIX_IDS}prefix_register_add-flow-edit-step_label_step-name`
export const PREFIX_REGISTER_ADDFLOWEDITSTEP_LABEL_MESSAGE12 = `${PREFIX_IDS}prefix_register_add-flow-edit-step_label_message-1-2`
export const PREFIX_REGISTER_ADDFLOWEDITSTEP_LABEL_MESSAGE3 = `${PREFIX_IDS}prefix_register_add-flow-edit-step_label_message-3`
export const PREFIX_REGISTER_ADDFLOWEDITSTEP_LABEL_MESSAGE4 = `${PREFIX_IDS}prefix_register_add-flow-edit-step_label_message-4`
export const PREFIX_REGISTER_ADDFLOWEDITSTEP_LABEL_MESSAGE5 = `${PREFIX_IDS}prefix_register_add-flow-edit-step_label_message-5`

// ADD FLOW REMOVE STEP
export const PREFIX_REGISTER_ADDFLOWREMOVESTEP_TITLE = `${PREFIX_IDS}prefix_register_add-flow-remove-step_modal_title`
export const PREFIX_REGISTER_ADDFLOWREMOVESTEP_BUTTON_CLOSE = `${PREFIX_IDS}prefix_register_add-flow-remove-step_button_close`
export const PREFIX_REGISTER_ADDFLOWREMOVESTEP_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_register_add-flow-remove-step_button_submit`
export const PREFIX_REGISTER_ADDFLOWREMOVESTEP_LABEL_STEPNAME = `${PREFIX_IDS}prefix_register_add-flow-remove-step_label_step-name`
export const PREFIX_REGISTER_ADDFLOWREMOVESTEP_LABEL_MESSAGE12 = `${PREFIX_IDS}prefix_register_add-flow-remove-step_label_message-1-2`

// FLOW LIST
export const PREFIX_REGISTER_FLOWLIST_BUTTON_ADDFLOW = `${PREFIX_IDS}prefix_register_flow-list_button_add-flow`