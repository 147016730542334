import { IncludeStep } from '@/presentation/components/include-step/include-step';
import { makeSelectValues, makeSelectValuesWithType } from '@/presentation/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, FormControl, Input, INPUT_TYPE, Paper, Select, CardTree, Modal, Button, Messages, Breadcrumbs } from 'fiorde-fe-components'
import React, { useEffect, useRef, useState } from 'react'
import { FlowDetailRemoveStep } from './flow-detail-remove-step';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { PREFIX_REGISTER_ADDFLOW_BREADCRUMBS, PREFIX_REGISTER_ADDFLOW_BUTTON_CLOSE, PREFIX_REGISTER_ADDFLOW_BUTTON_SUBMIT, PREFIX_REGISTER_ADDFLOW_CARDTREE, PREFIX_REGISTER_ADDFLOW_FORM, PREFIX_REGISTER_ADDFLOW_INPUT_NAME, PREFIX_REGISTER_ADDFLOW_INPUT_NAME_LABEL, PREFIX_REGISTER_ADDFLOW_LABEL_MESSAGE, PREFIX_REGISTER_ADDFLOW_PAPER, PREFIX_REGISTER_ADDFLOW_SELECT_ENDCOUNTING, PREFIX_REGISTER_ADDFLOW_SELECT_ENDCOUNTING_LABEL, PREFIX_REGISTER_ADDFLOW_SELECT_MOVEMENTTYPE, PREFIX_REGISTER_ADDFLOW_SELECT_MOVEMENTTYPE_LABEL, PREFIX_REGISTER_ADDFLOW_SELECT_STARTCOUNTING, PREFIX_REGISTER_ADDFLOW_SELECT_STARTCOUNTING_LABEL } from '@/ids';

export function FlowDetail({ loadMovementTypeList, loadStepAll, loadStep, addFlow, editFlow, loadFlow }) {
  const [values, setValues] = useState({
    name: '',
    movementTypes: [],
    steps: [],
    isActive: true
  })
  const [currentIndex, setCurrentIndex] = useState(null)
  const [visibleAddStep, setVisibleAddStep] = useState(false)
  const [visibleRemoveStep, setVisibleRemoveStep] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  })
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { id } = useParams()

  const { data: movementTypeList } = useQuery({
    queryKey: ["movement-type-all"],
    queryFn: async () => loadMovementTypeList.load(),
  });

  const { data } = useQuery({
    queryKey: [`flow-${id}`, id],
    queryFn: async () => loadFlow.load(id),
    enabled: !!id
  });

  useEffect(() => {
    if (id && data) {
      setValues(data)
    }
  }, [data])

  function handleSubmitAddStep(newStep) {
    loadStep.load(newStep)
      .then(res => {
        const step =   {
          "stepOrder": currentIndex + 1,
          "isCountingStart": false,
          "isCountingEnd": false,
          "isActive": true,
          "step": {
            ...res
          }
        }
        const steps = values.steps
        steps.splice(currentIndex, 0, step)
        const reorderSteps = steps.map((st, index) => ({...st, stepOrder: index + 1}))
        setValues({...values, steps: reorderSteps})
        resetIndex()
      })
    setVisibleAddStep(false)
  }
  function handleSubmitEditStep(index) {
    const firstStep = values.steps[index]?.step?.id
    navigate(`/step/list/${firstStep}`)
  }
  function handleSubmitRemoveStep(id) {
    const filteredSteps = values.steps.filter(f => f.step.id !== id)
    setValues({...values, steps: filteredSteps})
  }

  function handleAddStep(previousIndex: number) {
    setCurrentIndex(previousIndex + 1)
    setVisibleAddStep(true)
  }

  function handleRemoveStep(index: number) {
    setCurrentIndex(index)
    setVisibleRemoveStep(true)
  }

  function resetIndex() {
    setCurrentIndex(null)
  }

  function makeCards() {
    const steps = values.steps
    return steps.map(item => ({
      id: item?.step?.id?.toString(),
      title: item?.step?.name,
      subTitle: item?.step?.stepType?.name,
      description: item?.step?.description
    }))
  }

  function makeSelectedStartSteps() {
    const endStepId = filteredEndStep();
    const endStepIndex = values.steps.findIndex(f => f.step.id === endStepId);
  
    const filteredValues = endStepIndex !== -1 
      ? values.steps.slice(0, endStepIndex) 
      : values.steps;
  
    return filteredValues.map(item => item.step);
  }

  function makeSelectedEndSteps() {
    const startStepId = filteredStartStep();
    const startStepIndex = values.steps.findIndex(f => f.step.id === startStepId);
  
    const filteredValues = startStepId === undefined || startStepId === null || startStepIndex === -1 
      ? values.steps
      : values.steps.slice(startStepIndex + 1);
  
    return filteredValues.map(item => item.step);
  }

  function handleSelectStartStep(value) {
    const filteredStep = values.steps.map(item => item.step.id === value
      ? ({
        ...item,
        isCountingStart: true
        })
      : ({
        ...item,
        isCountingStart: false
      })
    )
    setValues({ ...values, steps: filteredStep })
  }

  function handleSelectEndStep(value) {
    const filteredStep = values.steps.map(item => item.step.id === value
      ? ({
        ...item,
        isCountingEnd: true
        })
      : ({
        ...item,
        isCountingEnd: false
      })
    )
    setValues({ ...values, steps: filteredStep })
  }

  function filteredStartStep() {
    const filteredStep = values.steps.find(item => item.isCountingStart === true)
    return filteredStep?.step?.id
  }

  function filteredEndStep() {
    const filteredStep = values.steps.find(item => item.isCountingEnd === true)
    return filteredStep?.step?.id
  }

  function makeStepId() {
    return values.steps[currentIndex]?.step?.id
  }

  function makeHiddenLastStep() {
    const filteredStep = values.steps[values.steps.length -1]
    return filteredStep?.step?.stepType?.id === 5
  }

  function handleSubmit(): void {
    if (id) {
      editFlow
        .put(id, {...values, id: id})
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ["flow-list"] });
          setResponseMessage({
            message: t("page.add-flow.response-success-message"),
            severity: "success",
          })
          navigate('/flow/list');
        })
        .catch((err) => {
          setResponseMessage({
            message: err?.message,
            severity: "error",
          });
        })
    } else {
      addFlow
        .post(values)
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ["flow-list"] });
          setResponseMessage({
            message: t("page.add-flow.response-success-message"),
            severity: "success",
          })
          navigate('/flow/list');
        })
        .catch((err) => {
          setResponseMessage({
            message: err?.message,
            severity: "error",
          });
        })
    }
  }

  function makeSaveDisabled() {
    if (values.name === '') return true
    if (values.movementTypes.length === 0) return true
    if (values.steps.length === 0) return true
    return false
  }

  function handleClose() {
    navigate('/flow/list');
  }

  return (
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '8px', alignItems: 'center', marginBottom: 20 }}>
        <Breadcrumbs
          id={PREFIX_REGISTER_ADDFLOW_BREADCRUMBS}
          menus={[
            {
              label: t("page.flow-add.breadcrumbs.flow"),
              onClick: () => navigate('/flow/list')
            },
            {
              label: t("page.flow-add.breadcrumbs.add-flow"),
              onClick: () => {}
            },
          ]}
          separator='>'
        />
      </div>
      <Paper
        id={PREFIX_REGISTER_ADDFLOW_PAPER}
        footer={[
          <Button
            id={PREFIX_REGISTER_ADDFLOW_BUTTON_CLOSE}
            data-testid="close-button"
            onAction={handleClose}
            text={t("page.flow-add.close")}
            noBackground
            backgroundGreen={false}
          />,
          <Button
            id={PREFIX_REGISTER_ADDFLOW_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={handleSubmit}
            text={t("page.flow-add.save")}
            type='submit'
            disabled={makeSaveDisabled()}
          />
        ]}
      >
        <Form id={PREFIX_REGISTER_ADDFLOW_FORM} onSubmit={() => {}}>
          <FormControl
            id={PREFIX_REGISTER_ADDFLOW_INPUT_NAME_LABEL}
            label={t("page.flow-add.form.name-label")}
            required
          >
            <Input
              id={PREFIX_REGISTER_ADDFLOW_INPUT_NAME}
              type={INPUT_TYPE.ALPHANUMERIC}
              placeholder={t("page.flow-add.form.name-placeholder")}
              value={values.name}
              onChange={(value) => setValues({...values, name: value})}
            />
          </FormControl>
          <FormControl
            id={PREFIX_REGISTER_ADDFLOW_SELECT_MOVEMENTTYPE_LABEL}
            label={t("page.flow-add.form.movement-type-label")}
            required
          >
            <Select
              id={PREFIX_REGISTER_ADDFLOW_SELECT_MOVEMENTTYPE}
              name="movementType"
              options={makeSelectValues({
                label: "name",
                value: "id",
                array: movementTypeList,
              })}
              onChange={(value) => setValues({...values, movementTypes: value.map(item => ({ id: item }))})}
              value={values.movementTypes.map(item => item.id)}
              multiple
              placeholder={t("page.flow-add.form.movement-type-placeholder")}
            />
          </FormControl>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
            <FormControl
              id={PREFIX_REGISTER_ADDFLOW_SELECT_STARTCOUNTING_LABEL}
              label={t("page.flow-add.form.start-counting-label")}
            >
              <Select
                id={PREFIX_REGISTER_ADDFLOW_SELECT_STARTCOUNTING}
                name="steplist"
                options={makeSelectValuesWithType({
                  label: "name",
                  type: "stepType.name",
                  value: "id",
                  array: makeSelectedStartSteps(),
                })}
                onChange={(value) => handleSelectStartStep(value)}
                value={filteredStartStep()}
                placeholder={t("page.flow-add.form.start-counting-placeholder")}
              />
            </FormControl>
            <FormControl
              id={PREFIX_REGISTER_ADDFLOW_SELECT_ENDCOUNTING_LABEL}
              label={t("page.flow-add.form.end-counting-label")}
            >
              <Select
                id={PREFIX_REGISTER_ADDFLOW_SELECT_ENDCOUNTING}
                name="steplist"
                options={makeSelectValuesWithType({
                  label: "name",
                  type: "stepType.name",
                  value: "id",
                  array: makeSelectedEndSteps(),
                })}
                onChange={(value) => handleSelectEndStep(value)}
                value={filteredEndStep()}
                placeholder={t("page.flow-add.form.end-counting-placeholder")}
              />
            </FormControl>
          </div>
          <div style={{maxWidth: 1200, margin: 'auto'}}>
            <CardTree
              id={PREFIX_REGISTER_ADDFLOW_CARDTREE}
              variant='solid'
              cards={makeCards()}
              onAdd={handleAddStep}
              onEdit={handleSubmitEditStep}
              onRemove={handleRemoveStep}
              label={t("page.flow-add.form.card-tree-button")}
              hiddenLastAddButton={makeHiddenLastStep()}
            />
          </div>
          <IncludeStep
            onSubmit={handleSubmitAddStep}
            loadStepAll={loadStepAll}
            isVisible={visibleAddStep}
            onClose={() =>{
              setVisibleAddStep(false)
              resetIndex()
            }}
            selectedSteps={values.steps.map(item => item)}
          />
          <FlowDetailRemoveStep
            id={makeStepId()}
            onSubmit={handleSubmitRemoveStep}
            loadStep={loadStep}
            isVisible={visibleRemoveStep}
            onClose={() =>{
              setVisibleRemoveStep(false)
              resetIndex()
            }}
          />
        </Form>
      </Paper>
      {responseMessage.message && (
        <Messages
          id={PREFIX_REGISTER_ADDFLOW_LABEL_MESSAGE}
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </div>
  )
}