import React, { useState } from "react";
import { UpdateDock as DomainUpdateDock, LoadDock } from "@/domain/usecase";
import {
  Button,
  Modal,
  Messages,
  IconButton,
  ProgressIndicator,
  Switch
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { mockLoadDockModel } from "@/domain/mocks";
import {
  PREFIX_REGISTER_DISABLEDOCK_ICONBUTTON_TOOLTIPACTIVEDISABLED,
  PREFIX_REGISTER_DISABLEDOCK_SWITCH,
  PREFIX_REGISTER_DISABLEDOCK_MODAL,
  PREFIX_REGISTER_DISABLEDOCK_BUTTON_CLOSE,
  PREFIX_REGISTER_DISABLEDOCK_BUTTON_SUBMIT,
} from "@/ids";

type DisableDockProps = {
  updateDock: DomainUpdateDock;
  loadDock: LoadDock;
  id: number;
  checked: boolean
};

export function DisableDock({ updateDock, loadDock, id, checked }: DisableDockProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const { data } = useQuery<any>({
    queryKey: [`dock-${id}`],
    queryFn: async () => await loadDock.load(id),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    updateDock
      .update(id, {
        ...data,
        isActive: !checked,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["dock-list"] });
        handleClose();
        setResponseMessage({
          message: checked
            ? t("components.disable-dock.response-success-message.active")
            : t("components.disable-dock.response-success-message.disabled"),
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        id={PREFIX_REGISTER_DISABLEDOCK_ICONBUTTON_TOOLTIPACTIVEDISABLED}
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={checked
          ? t("components.disable-dock.open-button-tooltip.active")
          : t("components.disable-dock.open-button-tooltip.disabled")
        }
      >
        <Switch id={PREFIX_REGISTER_DISABLEDOCK_SWITCH} checked={checked} />
      </IconButton>
      <Modal
        id={PREFIX_REGISTER_DISABLEDOCK_MODAL}
        width={600}
        title={checked
          ? t("components.disable-dock.title.active")
          : t("components.disable-dock.title.disabled")
        }
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_DISABLEDOCK_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.disable-dock.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_DISABLEDOCK_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.disable-dock.submit-button")}
          />,
        ]}
      >
        <span>
          {checked
            ? t("components.disable-dock.content.active")
            : t("components.disable-dock.content.disabled")
          } {data?.name}?
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
