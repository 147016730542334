import React, { useState } from "react";
import { AddSpot as DomainAddSpot, LoadSpotStatusList, LoadVehicleCompositionList } from "@/domain/usecase";
import {
  Button,
  Modal,
  FormControl,
  Input,
  Messages,
  ProgressIndicator,
  Select,
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import {
  PREFIX_REGISTER_ADDSPOT_BUTTON_OPEN,
  PREFIX_REGISTER_ADDSPOT_MODAL,
  PREFIX_REGISTER_ADDSPOT_BUTTON_CLOSE,
  PREFIX_REGISTER_ADDSPOT_BUTTON_SUBMIT,
  PREFIX_REGISTER_ADDSPOT_FORMCONTROL_INPUTNAME,
  PREFIX_REGISTER_ADDSPOT_INPUT_NAME,
  PREFIX_REGISTER_ADDSPOT_FORMCONTROL_INPUTLOCATION,
  PREFIX_REGISTER_ADDSPOT_INPUT_LOCATION,
  PREFIX_REGISTER_ADDSPOT_FORMCONTROL_SELECTVEHICLECOMPOSITION,
  PREFIX_REGISTER_ADDSPOT_FORMCONTROL_SELECTSPOTSTATUS,
} from "@/ids";

type AddSpotProps = {
  addSpot: DomainAddSpot;
  loadSpotStatusList: LoadSpotStatusList;
  loadVehicleCompositionList: LoadVehicleCompositionList
};

export function AddSpot({ addSpot, loadSpotStatusList, loadVehicleCompositionList }: AddSpotProps) {
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(false);
  const [name, setname] = useState("");
  const [location, setlocation] = useState("");
  const [spotStatus, setSpotStatus] = useState(null);
  const [vehicleCompositions, setVehicleCompositions] = useState([]);
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const { data: spotStatusList } = useQuery<any>({
    queryKey: ["spot-status"],
    queryFn: async () => await loadSpotStatusList.load(),
    enabled: open,
  });
  const { data: vehicleCompositionList } = useQuery<any>({
    queryKey: ["vehicle-composition-all"],
    queryFn: async () => await loadVehicleCompositionList.load(),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    addSpot
      .add({
        name,
        location,
        spotStatus: {
          id: spotStatus,
        },
        vehicleCompositions: vehicleCompositions?.map(vehicleCompositionId => ({ id: vehicleCompositionId })),
        isActive: true,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["spot-list"] });
        setResponseMessage({
          message: t("components.add-spot.response-success-message"),
          severity: "success",
        });
        handleClose();
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setname("");
    setlocation("");
    setSpotStatus([]);
    setOpen(false);
    setVehicleCompositions([])
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <Button
        id={PREFIX_REGISTER_ADDSPOT_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-spot.open-button")}
      />
      <Modal
        id={PREFIX_REGISTER_ADDSPOT_MODAL}
        width={600}
        title={t("components.add-spot.open-button")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_ADDSPOT_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            noBackground
            onAction={() => handleClose()}
            text={t("components.add-spot.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_ADDSPOT_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-spot.submit-button")}
          />,
        ]}
      >
        <FormControl 
          id={PREFIX_REGISTER_ADDSPOT_FORMCONTROL_INPUTNAME}
          label={t("components.add-spot.input-label-name")}>
          <Input
            inputProps={{ "data-testid": "name" }}
            id={PREFIX_REGISTER_ADDSPOT_INPUT_NAME}
            onChange={value => setname(value)}
            value={name}
            placeholder={t("components.add-spot.input-placeholder-name")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDSPOT_FORMCONTROL_INPUTLOCATION}
          label={t("components.add-spot.input-label-location")}>
          <Input
            inputProps={{ "data-testid": "location" }}
            id={PREFIX_REGISTER_ADDSPOT_INPUT_LOCATION}
            onChange={value => setlocation(value)}
            value={location}
            placeholder={t("components.add-spot.input-placeholder-location")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDSPOT_FORMCONTROL_SELECTVEHICLECOMPOSITION}
          label={t("components.add-spot.vehicle-composition-label")}>
          <Select
            name="vehicleComposition"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: vehicleCompositionList,
            })}
            onChange={(value) => setVehicleCompositions(value)}
            value={vehicleCompositions}
            multiple
            placeholder={t("components.add-spot.vehicle-composition-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDSPOT_FORMCONTROL_SELECTSPOTSTATUS}
          label={t("components.add-spot.spot-status-label")}>
          <Select
            name="spotStatus"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: spotStatusList,
            })}
            onChange={(value) => setSpotStatus(value)}
            value={spotStatus}
            placeholder={t("components.add-spot.spot-status-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
